import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { FlipActiveDailyPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const flipActiveDailyV1: ProductInfo = {
    id: Cover.FLIP_ACTIVE_DAILY,
    icon: DayPassIcon,
    name: 'Flip Active Day',
    group: 'Flip Active',
    pluralisedName: 'Flip Active Days',
    price: '$7',
    priceTimePeriod: 'day',
    description: 'One time payment',
    primaryFeatures: ['Pay by the day', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'By the day',
    coverUnitDuration: 'per day',
    paymentModelDescription: 'One time payment',
    shortDescription: 'Add as many days as you want',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipActiveDailyProductInfo: Record<FlipActiveDailyPdsVersion, ProductInfo> = {
    [FlipActiveDailyPdsVersion.V1]: flipActiveDailyV1,
    [FlipActiveDailyPdsVersion.V1_KIDS]: flipActiveDailyV1,
    [FlipActiveDailyPdsVersion.FLIP_2_0]: flipActiveDailyV1,
    [FlipActiveDailyPdsVersion.FLIP_2_1]: flipActiveDailyV1,
};

export default flipActiveDailyProductInfo;
