import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../../analytics/Analytics';
import cancelIcon from '../../../assets/images/cancel-circle.svg';
import Card from '../../../components/card/Card';
import SlideInCoverTermsCTA from '../../../components/slide-in-cover-terms-cta/SlideInCoverTermsCTA';
import PageTitles from '../../../utils/constants/PageTitles';
import { MainCoverPdsVersion } from '../../../utils/constants/PdsVersion';
import formatPrice from '../../../utils/formatPrice';
import { Route } from '../../../utils/Routes';

import './ManageCoverItemCard.scss';
import CoverCode from '../../../utils/constants/CoverCode';

interface ManageCoverItemCardProps {
    coverName: string;
    cover: CoverCode;
    coverItems: {
        paymentModel: string;
        lineItems: {
            key: string;
            description: ReactNode;
            region?: ReactNode;
            price?: number | undefined;
        }[];
        canCancel: boolean;
    }[];
    pdsVersion: MainCoverPdsVersion;
    benefitsContent: {
        benefitsCTA: string;
    };
    onCancel: () => void;
}

const ManageCoverItemCard: FC<ManageCoverItemCardProps> = ({
    coverName,
    cover,
    coverItems,
    onCancel,
    pdsVersion,
    benefitsContent,
}) => {
    const history = useHistory();
    const trackBenefitsDrawer = (coverCode: CoverCode) => {
        const pageName = PageTitles[history?.location.pathname as Route];
        Analytics.trackBenefitsDrawerViewed(pageName, coverCode);
    };
    return (
        <Card className="manage-cover-item-card__card">
            <p className="manage-cover-item-card__cover-name">{coverName}</p>
            {coverItems.map((c) => (
                <div className="manage-cover-item-card__cover-item" key={c.paymentModel}>
                    <div className="manage-cover-item-card__cover-item-heading">
                        <span className="manage-cover-item-card__payment-model">{c.paymentModel}</span>
                        {c.canCancel && (
                            <button type="button" onClick={onCancel} className="manage-cover-item-card__cancel-button">
                                <img src={cancelIcon} alt="Cancel" />
                            </button>
                        )}
                    </div>
                    {c.lineItems.map((i) => (
                        <div className="manage-cover-item-card__line-item" key={i.key}>
                            <div className="manage-cover-item-card__line-item__content">
                                <span>{i.description}</span>
                                <p className="manage-cover-item-card__line-item__content--destination">{i.region}</p>
                            </div>
                            {i.price !== undefined && (
                                <span>
                                    <strong>{formatPrice(i.price)}</strong>
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <SlideInCoverTermsCTA
                coverCode={cover}
                pdsVersion={pdsVersion}
                benefitsContent={benefitsContent}
                className="manage-cover-item-card__slide-cta"
                trackDrawerOpen={() => {
                    trackBenefitsDrawer(cover);
                }}
            />
        </Card>
    );
};

export default ManageCoverItemCard;
