import { useOktaAuth } from '@okta/okta-react';
import { useMachine } from '@xstate/react';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useUser } from '../../business-logic/context-provider/user-context/UserContext';
import Layout from '../../components/layout/Layout';
import CreditBanner from '../../components/layout/banners/credit-banner/CreditBanner';
import cartBaymaxContent from '../../content/ui/screens/cart-baymax/cartBaymax';
import withContent from '../../hoc/with-content/withContent';
import useLazyDependency from '../../hooks/lazy-dependency/useLazyDependency';
import useCoverInformation from '../../hooks/useCoverInformation';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import StepItem from '../guest-cart/components/step-item/StepItem';
import ProfileBaymaxStep from '../guest-cart/steps/profile/ProfileBaymaxStep';
import cartBaymaxMachine from './cart-baymax-machine/cartBaymaxMachine';
import StepProgressBarBaymax from './components/step-progress-bar/StepProgressBarBaymax';
import CartBaymaxStep from './constants/CartBaymaxStep';
import PaymentBaymaxStep from './steps/payment/PaymentBaymaxStep';
import PersonsInsuredBaymax from './steps/persons-insured/PersonsInsuredBaymax';
import ReviewCoverBaymaxStep from './steps/review-cover/ReviewCoverBaymaxStep';

import './CartBaymax.scss';

const contentMap = {
    header: 'ui.header',
    selectProductStepTitle: 'ui.selectProductStep.title',
    selectProductStepDescription: 'ui.selectProductStep.description',
    selectProductStepFlipActiveDescription: 'ui.selectProductStep.descriptionFlipActive',
    registerStepTitle: 'ui.registerStep.title',
    registerStepDescription: 'ui.registerStep.description',
    profileStepTitle: 'ui.profileStep.title',
    profileStepDescription: 'ui.profileStep.description',
    whosCoveredStepTitle: 'ui.whosCoveredStep.title',
    whosCoveredStepDescription: 'ui.whosCoveredStep.description',
    reviewCoverStepTitle: 'ui.reviewCoverStep.title',
    reviewCoverStepDescription: 'ui.reviewCoverStep.description',
    paymentStepTitle: 'ui.paymentStep.title',
    referralVerificationSuccess: 'ui.referral.referralVerificationSuccess',
    referralVerificationError: 'ui.referral.referralVerificationError',
};

interface CartBaymaxProps {
    content: Record<keyof typeof contentMap, string>;
}

const CartBaymax: FC<CartBaymaxProps> = ({ content }) => {
    const {
        creditBalance: creditBalanceLazyDependency,
        userDetails,
        loading: userLoading,
        initialised: userInitialised,
        userTimeZone,
        profileCompleted,
        residencyCompleted,
    } = useUser();
    const location = useLocation<PurchaseState>();
    const {
        products,
        productGroups,
        loading: baymaxProductsLoading,
        initialised: baymaxProductsInitialised,
    } = useProduct();
    const creditBalance = useLazyDependency(creditBalanceLazyDependency);
    const { authState } = useOktaAuth();
    const history = useHistory();
    const coverInformation = useCoverInformation(location.state.selectedProductOption?.representedByCoverCode ?? '');
    const [state, send] = useMachine(cartBaymaxMachine, {
        context: {
            isExistingUser: profileCompleted && residencyCompleted,
            purchaseState: location.state,
        },
        actions: {
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER_BAYMAX,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        destinations: null,
                    },
                });
            },
        },
    });

    const { currentStep, showStep, steps } = state.context;
    const hasKids = !!steps.find((s) => s === CartBaymaxStep.WHOS_COVERED);

    const layoutHasBanner = !!creditBalance.value;

    const profileDescription =
        userLoading || !userInitialised || !state.context.isExistingUser
            ? content.profileStepDescription
            : userDetails.email;

    useEffect(() => {
        if (!baymaxProductsLoading && baymaxProductsInitialised && products.length) {
            send({
                type: 'CONTEXT_INITIALISED',
                data: {
                    products,
                    productGroups,
                },
            });
        }
    }, [
        products,
        baymaxProductsInitialised,
        baymaxProductsLoading,
        send,
        userDetails,
        productGroups,
        authState?.isAuthenticated,
        state.context,
    ]);

    const renderStepItem = (step: CartBaymaxStep, index: number) => {
        switch (step) {
            case CartBaymaxStep.PROFILE:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.profileStepTitle}
                        description={profileDescription}
                        open={showStep === CartBaymaxStep.PROFILE}
                        onToggle={() => send('TOGGLE_PROFILE')}
                        completed={currentStep > CartBaymaxStep.PROFILE}
                        disabled={!state.can('TOGGLE_PROFILE') || state.context.isExistingUser}
                        scrollToTopOnOpen
                    >
                        <ProfileBaymaxStep
                            showKidsInfo={hasKids}
                            // We don't support mixing of Flip Active and Flip Kids in cart right now
                            // So if there is kids in the cart it means it's only kids
                            isPurchasingKidsCoverOnly={hasKids}
                            onProfileUpdateComplete={() => send('PROFILE_UPDATE_COMPLETE')}
                        />
                    </StepItem>
                );

            case CartBaymaxStep.WHOS_COVERED:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.whosCoveredStepTitle}
                        description={content.whosCoveredStepDescription}
                        open={showStep === CartBaymaxStep.WHOS_COVERED}
                        onToggle={() => send('TOGGLE_PERSONS_INSURED')}
                        completed={currentStep > CartBaymaxStep.WHOS_COVERED}
                        disabled={!state.can('TOGGLE_PERSONS_INSURED')}
                        scrollToTopOnOpen
                    >
                        <PersonsInsuredBaymax
                            selectedPersons={state.context.insuredPersons}
                            coverStartDates={state.context.purchaseState?.coverStartDates ?? []}
                            showAccountHolder={state.context.availableInsuredPersonsTypes.includes('accountholder')}
                            onPersonsUpdateComplete={(persons, isAccountHolderInsured) =>
                                send({
                                    type: 'PERSONS_INSURED_COMPLETE',
                                    data: { dependants: persons, isAccountHolderInsured },
                                })
                            }
                        />
                    </StepItem>
                );

            case CartBaymaxStep.REVIEW_COVER:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.reviewCoverStepTitle}
                        description={content.reviewCoverStepDescription}
                        open={showStep === CartBaymaxStep.REVIEW_COVER}
                        onToggle={() => send('TOGGLE_REVIEW_COVER')}
                        completed={currentStep > CartBaymaxStep.REVIEW_COVER}
                        disabled={!state.can('TOGGLE_REVIEW_COVER')}
                        scrollToTopOnOpen
                    >
                        <ReviewCoverBaymaxStep
                            purchaseState={state.context.purchaseState!}
                            products={state.context.products}
                            productGroups={state.context.productGroups!}
                            coverInformation={coverInformation}
                            userTimeZone={userTimeZone}
                            insuredPersons={state.context.insuredPersons}
                            onContinueToPayment={() => send('CONTINUE_TO_PAYMENT')}
                        />
                    </StepItem>
                );

            case CartBaymaxStep.PAYMENT:
                return (
                    <StepItem
                        key={step}
                        stepIndex={index}
                        title={content.paymentStepTitle}
                        open={showStep === CartBaymaxStep.PAYMENT}
                        onToggle={() => send('TOGGLE_PAYMENT')}
                        completed={currentStep > CartBaymaxStep.PAYMENT}
                        disabled={!state.can('TOGGLE_PAYMENT')}
                        scrollToTopOnOpen
                    >
                        <div />
                        <PaymentBaymaxStep
                            products={state.context.products}
                            productGroups={state.context.productGroups}
                            purchaseState={state.context.purchaseState!}
                            insuredPersons={state.context.insuredPersons}
                            onInvalidCoverSelection={() => send('TOGGLE_PROFILE')}
                            onPaymentComplete={() => send('PAYMENT_COMPLETE')}
                            onGoToPrevStep={() => send('TOGGLE_REVIEW_COVER')}
                        />
                    </StepItem>
                );

            default:
                return null;
        }
    };

    return (
        <Layout banner={<CreditBanner />}>
            <StepProgressBarBaymax steps={steps} currentStep={currentStep} layoutHasBanner={layoutHasBanner} />
            <h1 className="guest-cart__header">{content.header}</h1>
            {steps.map(renderStepItem)}
        </Layout>
    );
};

export default withContent(CartBaymax, contentMap, cartBaymaxContent);
