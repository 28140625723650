import Cover from '../utils/constants/Cover';
import CoverInformation from '../utils/constants/CoverInformation';

const ProductList: Record<
    Cover,
    { product_id: string; sku: string; name: string; brand: 'Flip'; price: number; position?: number }
> = {
    [Cover.DAY_PASS]: {
        product_id: CoverInformation[Cover.DAY_PASS].coverCode,
        sku: CoverInformation[Cover.DAY_PASS].coverCode,
        name: 'Day Pass',
        brand: 'Flip',
        price: 6.0,
        position: 2,
    },
    [Cover.ALWAYS_ON]: {
        product_id: CoverInformation[Cover.ALWAYS_ON].coverCode,
        sku: CoverInformation[Cover.ALWAYS_ON].coverCode,
        name: 'Always On',
        brand: 'Flip',
        price: 9.0,
        position: 1,
    },
    [Cover.BOOST]: {
        product_id: CoverInformation[Cover.BOOST].coverCode,
        sku: CoverInformation[Cover.BOOST].coverCode,
        name: 'Boost',
        brand: 'Flip',
        price: 6.0,
    },
    [Cover.JAPAN_PASS]: {
        product_id: CoverInformation[Cover.JAPAN_PASS].coverCode,
        sku: CoverInformation[Cover.JAPAN_PASS].coverCode,
        name: 'Japan Pass',
        brand: 'Flip',
        price: 25.0,
    },
    [Cover.FLIP_ACTIVE_DAILY]: {
        product_id: CoverInformation[Cover.FLIP_ACTIVE_DAILY].coverCode,
        sku: CoverInformation[Cover.FLIP_ACTIVE_DAILY].coverCode,
        name: 'Flip Active Day',
        brand: 'Flip',
        price: 7.0,
        position: 1,
    },
    [Cover.FLIP_ACTIVE_WEEKLY]: {
        product_id: CoverInformation[Cover.FLIP_ACTIVE_WEEKLY].coverCode,
        sku: CoverInformation[Cover.FLIP_ACTIVE_WEEKLY].coverCode,
        name: 'Flip Active Week',
        brand: 'Flip',
        price: 25.0,
        position: 2,
    },
    [Cover.FLIP_ACTIVE_SUB_MONTHLY]: {
        product_id: CoverInformation[Cover.FLIP_ACTIVE_SUB_MONTHLY].coverCode,
        sku: CoverInformation[Cover.FLIP_ACTIVE_SUB_MONTHLY].coverCode,
        name: 'Flip Active Subscription',
        brand: 'Flip',
        price: 40.0,
        position: 3,
    },
    [Cover.FLIP_ACTIVE_BOOST]: {
        product_id: CoverInformation[Cover.FLIP_ACTIVE_BOOST].coverCode,
        sku: CoverInformation[Cover.FLIP_ACTIVE_BOOST].coverCode,
        name: 'Boost',
        brand: 'Flip',
        price: 7.0,
    },
    [Cover.FLIP_KIDS_DAILY]: {
        product_id: CoverInformation[Cover.FLIP_KIDS_DAILY].coverCode,
        sku: CoverInformation[Cover.FLIP_KIDS_DAILY].coverCode,
        name: 'Flip Kids Day',
        brand: 'Flip',
        price: 7.0,
        position: 1,
    },
    [Cover.FLIP_KIDS_WEEKLY]: {
        product_id: CoverInformation[Cover.FLIP_KIDS_WEEKLY].coverCode,
        sku: CoverInformation[Cover.FLIP_KIDS_WEEKLY].coverCode,
        name: 'Flip Kids Week',
        brand: 'Flip',
        price: 25.0,
        position: 2,
    },
    [Cover.FLIP_KIDS_SUB_MONTHLY]: {
        product_id: CoverInformation[Cover.FLIP_KIDS_SUB_MONTHLY].coverCode,
        sku: CoverInformation[Cover.FLIP_KIDS_SUB_MONTHLY].coverCode,
        name: 'Flip Kids Subscription',
        brand: 'Flip',
        price: 40.0,
        position: 3,
    },
    [Cover.FLIP_ROAMING_WEEKLY]: {
        product_id: CoverInformation[Cover.FLIP_ROAMING_WEEKLY].coverCode,
        sku: CoverInformation[Cover.FLIP_ROAMING_WEEKLY].coverCode,
        name: 'Flip Roaming',
        brand: 'Flip',
        price: 80.0,
        position: 4,
    },
    [Cover.FLIP_ROAMING_KIDS_WEEKLY]: {
        product_id: CoverInformation[Cover.FLIP_ROAMING_KIDS_WEEKLY].coverCode,
        sku: CoverInformation[Cover.FLIP_ROAMING_KIDS_WEEKLY].coverCode,
        name: 'Flip Roaming',
        brand: 'Flip',
        price: 80.0,
        position: 4,
    },
    [Cover.FLIP_ACTIVE_90_DAYS]: {
        product_id: '',
        sku: '',
        name: 'Flip Active 90 day cover',
        brand: 'Flip',
        price: 99.0,
        position: 4,
    },
    [Cover.FLIP_ACTIVE_180_DAYS]: {
        product_id: '',
        sku: '',
        name: 'Flip Active 180 day cover',
        brand: 'Flip',
        price: 180.0,
        position: 5,
    },
    [Cover.FLIP_KIDS_90_DAYS]: {
        product_id: '',
        sku: '',
        name: 'Flip Kids 90 day cover',
        brand: 'Flip',
        price: 99.0,
        position: 4,
    },
    [Cover.FLIP_KIDS_180_DAYS]: {
        product_id: '',
        sku: '',
        name: 'Flip Kids 180 day cover',
        brand: 'Flip',
        price: 180.0,
        position: 5,
    },
};

export default ProductList;
