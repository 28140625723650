import LocalizedStrings from 'react-localization';
import Cover from '../../../utils/constants/Cover';
import ByProduct from '../ByProduct';
import alwaysOnProductInfo from './alwaysOnProductInfo';
import boostProductInfo from './boostProductInfo';
import dayPassProductInfo from './dayPassProductInfo';
import flipActiveBoostProductInfo from './flipActiveBoostProductInfo';
import flipActiveDailyProductInfo from './flipActiveDailyProductInfo';
import flipActiveSubMonthlyProductInfo from './flipActiveSubMonthlyProductInfo';
import flipActiveWeeklyProductInfo from './flipActiveWeeklyProductInfo';
import flipKids180DaysProductInfo from './flipKids180DaysProductInfo';
import flipKids90DaysProductInfo from './flipKids90DaysProductInfo';
import flipKidsDailyProductInfo from './flipKidsDailyProductInfo';
import flipKidsSubMonthlyProductInfo from './flipKidsSubMonthlyProductInfo';
import flipKidsWeeklyProductInfo from './flipKidsWeeklyProductInfo';
import flipRoamingKidsProductInfo from './flipRoamingKidsProductInfo';
import flipRoamingProductInfo from './flipRoamingProductInfo';
import japanPassProductInfo from './japanPassProductInfo';
import { ProductInfo } from './types';
import flipActive180DaysProductInfo from './flipActive180DaysProductInfo';
import flipActive90DaysProductInfo from './flipActive90DaysProductInfo';

const productInfo = new LocalizedStrings<ByProduct<ProductInfo>>({
    en: {
        [Cover.ALWAYS_ON]: alwaysOnProductInfo,
        [Cover.BOOST]: boostProductInfo,
        [Cover.DAY_PASS]: dayPassProductInfo,
        [Cover.JAPAN_PASS]: japanPassProductInfo,
        [Cover.FLIP_ACTIVE_DAILY]: flipActiveDailyProductInfo,
        [Cover.FLIP_ACTIVE_WEEKLY]: flipActiveWeeklyProductInfo,
        [Cover.FLIP_ACTIVE_SUB_MONTHLY]: flipActiveSubMonthlyProductInfo,
        [Cover.FLIP_KIDS_DAILY]: flipKidsDailyProductInfo,
        [Cover.FLIP_KIDS_WEEKLY]: flipKidsWeeklyProductInfo,
        [Cover.FLIP_ACTIVE_90_DAYS]: flipActive90DaysProductInfo,
        [Cover.FLIP_ACTIVE_180_DAYS]: flipActive180DaysProductInfo,
        [Cover.FLIP_KIDS_90_DAYS]: flipKids90DaysProductInfo,
        [Cover.FLIP_KIDS_180_DAYS]: flipKids180DaysProductInfo,
        [Cover.FLIP_KIDS_SUB_MONTHLY]: flipKidsSubMonthlyProductInfo,
        [Cover.FLIP_ACTIVE_BOOST]: flipActiveBoostProductInfo,
        [Cover.FLIP_ROAMING_WEEKLY]: flipRoamingProductInfo,
        [Cover.FLIP_ROAMING_KIDS_WEEKLY]: flipRoamingKidsProductInfo,
    },
});

export default productInfo;
