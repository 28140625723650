import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { FlipActive180DaysPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const flipActive180DaysV1: ProductInfo = {
    id: Cover.FLIP_ACTIVE_180_DAYS,
    icon: DayPassIcon,
    name: 'Flip Active 180 Day',
    group: 'Flip Active',
    pluralisedName: 'Flip Active 180 Days',
    price: '$180',
    priceTimePeriod: '180 Day',
    description: 'One time payment',
    primaryFeatures: ['Pay by the week', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: '180 Day',
    coverUnitDuration: 'per 180 Days',
    paymentModelDescription: 'One time payment',
    shortDescription: 'Add as many rounds of 180 Days as you want',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipActive180DaysProductInfo: Record<FlipActive180DaysPdsVersion, ProductInfo> = {
    [FlipActive180DaysPdsVersion.FLIP_2_1]: flipActive180DaysV1,
};

export default flipActive180DaysProductInfo;
