import classNames from 'classnames';
import { FC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import CoverStatusCard from '../../components/home/cover-status-card/CoverStatusCard';
import HomeLayout from '../../components/home/home-layout/HomeLayout';
import RiveButton from '../../components/home/rive-button/RiveButton';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import PurchaseMoreCover from '../../components/purchase-more-cover/PurchaseMoreCover';
import homeV2Content from '../../content/ui/screens/home-v2/homeV2';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import Routes from '../../utils/Routes';
import CoverStatus from '../../utils/constants/CoverStatus';
import RiveAnimation from '../../utils/constants/RiveAnimation';
import CoverListCard from './cover-list-card/CoverListCard';
import getDashboardState from './utils/getDashboardState';

import './HomeV2.scss';

const contentMap = {
    noCoverScheduled: 'ui.noCoverScheduled',
    coverIsScheduled: 'ui.coverIsScheduled',
    coverIsActive: 'ui.coverIsActive',
};

interface HomeV2Props {
    content: Record<keyof typeof contentMap, string>;
}

const HomeV2: FC<HomeV2Props> = ({ content }) => {
    const history = useHistory();
    const { profileCompleted, loading: userLoading, dependants, userDetails } = useUser();

    const { openCoversLoading, fetchOpenCovers, policies, partnerEvents } = useOpenCovers();

    const accountHolderCovers = policies.filter(
        (x) => x.mainCover.insuredPersonId === null || x.mainCover.insuredPersonId === userDetails.personId,
    );

    const dependantsCovers = policies.filter(
        (x) => !!dependants.find((y) => y.personId === x.mainCover.insuredPersonId),
    );

    const isAppLoading = userLoading || openCoversLoading;

    const dashboardState = getDashboardState(policies, partnerEvents);

    const title = useMemo(() => {
        switch (dashboardState) {
            case 'active':
                return content.coverIsActive;
            case 'scheduled':
                return content.coverIsScheduled;
            default:
                return content.noCoverScheduled;
        }
    }, [content.coverIsActive, content.coverIsScheduled, content.noCoverScheduled, dashboardState]);

    const handleApplyCover = () => {
        history.push(profileCompleted ? Routes.SELECT_COVER_BAYMAX : Routes.DATE_OF_BIRTH);
    };

    useEffect(() => {
        fetchOpenCovers();
    }, []);

    if (isAppLoading) {
        return <LoadingSpinnerOverlay />;
    }

    return (
        <HomeLayout title={title} coverStatus={dashboardState === 'active' ? CoverStatus.ACTIVE : CoverStatus.INACTIVE}>
            <CoverStatusCard>
                <RiveButton
                    animationName={
                        dashboardState === 'active' ? RiveAnimation.ON_BOOST_INACTIVE : RiveAnimation.ON_SCHEDULED
                    }
                    onClick={handleApplyCover}
                />
            </CoverStatusCard>

            <div className={classNames('home-v2')}>
                {dashboardState !== 'noCover' && (
                    <div className="home-v2__column">
                        {/* User's cover */}
                        <div className="home-v2__cover-list-card">
                            <CoverListCard policies={accountHolderCovers} partnerEvents={partnerEvents} />
                        </div>
                        {/* Dependant's cover */}
                        {dependants.length > 0 &&
                            dependantsCovers.length > 0 &&
                            dependants
                                .sort((a, b) => a.firstName!.localeCompare(b.firstName!))
                                .map((d) => {
                                    const childPolicies = dependantsCovers.filter(
                                        (x) => x.mainCover.insuredPersonId === d.personId,
                                    );
                                    return childPolicies ? (
                                        <div className="home-v2__cover-list-card" key={d.personId}>
                                            <CoverListCard
                                                policies={childPolicies}
                                                firstName={d.firstName}
                                                {...childPolicies}
                                                partnerEvents={[]}
                                            />
                                        </div>
                                    ) : null;
                                })}
                    </div>
                )}

                <div className="home-v2__column">
                    <PurchaseMoreCover onBuyNowClick={handleApplyCover} />
                </div>
            </div>
        </HomeLayout>
    );
};

export default requireFlags(withContent(HomeV2, contentMap, homeV2Content));
