enum Cover {
    BOOST = 'boost',
    DAY_PASS = 'dayPass',
    ALWAYS_ON = 'alwaysOn',
    JAPAN_PASS = 'japanPass',
    FLIP_ACTIVE_DAILY = 'flipActiveDaily',
    FLIP_ACTIVE_WEEKLY = 'flipActiveWeekly',
    FLIP_ACTIVE_90_DAYS = 'flipActive90Days',
    FLIP_ACTIVE_180_DAYS = 'flipActive180Days',
    FLIP_ACTIVE_SUB_MONTHLY = 'flipActiveSubMonthly',
    FLIP_KIDS_DAILY = 'flipKidsDaily',
    FLIP_KIDS_WEEKLY = 'flipKidsWeekly',
    FLIP_KIDS_90_DAYS = 'flipKids90Days',
    FLIP_KIDS_180_DAYS = 'flipKids180Days',
    FLIP_KIDS_SUB_MONTHLY = 'flipKidsSubMonthly',
    FLIP_ACTIVE_BOOST = 'flipActiveBoost',
    FLIP_ROAMING_WEEKLY = 'flipRoaming',
    FLIP_ROAMING_KIDS_WEEKLY = 'flipRoamingKids',
}

export type MainCover =
    | Cover.ALWAYS_ON
    | Cover.DAY_PASS
    | Cover.JAPAN_PASS
    | Cover.FLIP_ACTIVE_DAILY
    | Cover.FLIP_ACTIVE_WEEKLY
    | Cover.FLIP_ACTIVE_90_DAYS
    | Cover.FLIP_ACTIVE_180_DAYS
    | Cover.FLIP_ACTIVE_SUB_MONTHLY
    | Cover.FLIP_KIDS_DAILY
    | Cover.FLIP_KIDS_WEEKLY
    | Cover.FLIP_KIDS_90_DAYS
    | Cover.FLIP_KIDS_180_DAYS
    | Cover.FLIP_KIDS_SUB_MONTHLY
    | Cover.FLIP_ROAMING_WEEKLY
    | Cover.FLIP_ROAMING_KIDS_WEEKLY;

export default Cover;
