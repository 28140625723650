import Cover from '../../../utils/constants/Cover';
import AlwaysOnIcon from '../../../assets/images/cover/always-on.svg';
import { ProductInfo } from './types';
import { FlipKidsSubMonthlyPdsVersion } from '../../../utils/constants/PdsVersion';

const flipKidsSubMonthlyV1: ProductInfo = {
    id: Cover.FLIP_KIDS_SUB_MONTHLY,
    icon: AlwaysOnIcon,
    name: 'Flip Kids Subscription',
    group: 'Flip Kids',
    price: '$40',
    priceTimePeriod: 'month',
    priceTimePeriodDescription: 'Recurring monthly',
    description: 'Ongoing cover for peace of mind',
    primaryFeatures: ['Monthly subscription, cancel anytime', 'Boost for $7 per day to double your payout for the day'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'Subscription',
    coverUnitDuration: 'Subscription',
    paymentModelDescription: 'Monthly subscription, cancel anytime',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipKidsSubMonthlyProductInfo: Record<FlipKidsSubMonthlyPdsVersion, ProductInfo> = {
    [FlipKidsSubMonthlyPdsVersion.V1]: flipKidsSubMonthlyV1,
    [FlipKidsSubMonthlyPdsVersion.FLIP_2_0]: flipKidsSubMonthlyV1,
    [FlipKidsSubMonthlyPdsVersion.FLIP_2_1]: flipKidsSubMonthlyV1,
};

export default flipKidsSubMonthlyProductInfo;
