import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { FlipActiveWeeklyPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const flipActiveWeeklyV1: ProductInfo = {
    id: Cover.FLIP_ACTIVE_WEEKLY,
    icon: DayPassIcon,
    name: 'Flip Active Week',
    group: 'Flip Active',
    pluralisedName: 'Flip Active Weeks',
    price: '$25',
    priceTimePeriod: 'week',
    description: 'One time payment',
    primaryFeatures: ['Pay by the week', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: 'By the week',
    coverUnitDuration: 'per week',
    paymentModelDescription: 'One time payment',
    shortDescription: 'Add as many weeks as you want',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipActiveWeeklyProductInfo: Record<FlipActiveWeeklyPdsVersion, ProductInfo> = {
    [FlipActiveWeeklyPdsVersion.V1]: flipActiveWeeklyV1,
    [FlipActiveWeeklyPdsVersion.V1_KIDS]: flipActiveWeeklyV1,
    [FlipActiveWeeklyPdsVersion.FLIP_2_0]: flipActiveWeeklyV1,
    [FlipActiveWeeklyPdsVersion.FLIP_2_1]: flipActiveWeeklyV1,
};

export default flipActiveWeeklyProductInfo;
