import axios from 'axios';
import asService from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import { AllCoverTerms } from '../../business-logic/models/cdn-content/CoverTerms';

const baseApiPath = process.env.REACT_APP_BASE_CDN_PATH;

const getCoverTerms = async (): Promise<AllCoverTerms> => {
    try {
        const { data } = await axios.get<AllCoverTerms>(`${baseApiPath}/json/coverTermsV2.json`);
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve cover terms from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving cover terms from Flip content delivery network`);
    }
};

export default asService(getCoverTerms, false);
