import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { FlipActive90DaysPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const flipActive90DaysV1: ProductInfo = {
    id: Cover.FLIP_ACTIVE_90_DAYS,
    icon: DayPassIcon,
    name: 'Flip Active 90 Day',
    group: 'Flip Active',
    pluralisedName: 'Flip Active 90 Days',
    price: '$99',
    priceTimePeriod: '90 Day',
    description: 'One time payment',
    primaryFeatures: ['Pay by the week', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: '90 Day',
    coverUnitDuration: 'per 90 Days',
    paymentModelDescription: 'One time payment',
    shortDescription: 'Add as many rounds of 90 Days as you want',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipActive90DaysProductInfo: Record<FlipActive90DaysPdsVersion, ProductInfo> = {
    [FlipActive90DaysPdsVersion.FLIP_2_1]: flipActive90DaysV1,
};

export default flipActive90DaysProductInfo;
