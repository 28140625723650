import cancelIcon from '../../../../assets/images/cancel-circle.svg';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import { Policy } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { ActionItemProps } from '../../../../components/action-list/action-item/ActionItem';
import Button from '../../../../components/button/Button';
import ExternalLink from '../../../../components/external-link/ExternalLink';
import coverListCardContent from '../../../../content/ui/components/cover-list-card/coverListCard';
import common from '../../../../strings/common';
import Routes from '../../../../utils/Routes';
import DateFormat from '../../../../utils/constants/DateFormat';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';
import unixNumToString from '../../../../utils/unixNumToString';
import ActiveBadge from '../active-badge/ActiveBadge';
import renderPdsVersion from './renderPdsVersion';

const createSubscriptionCoverListItem = (
    subscription: Policy,
    timeZone: string,
    coverInformation: AllCoverInformation,
    onSubscriptionCancel: (coverId: string) => void,
    onBillingCenterClick: () => void,
): ActionItemProps => {
    const productInfo = coverInformation[subscription.mainCover.coverCode][subscription.PDSVersion];
    const isSubscriptionPaymentFailed = subscription.mainCover.paymentFailed;
    const isCoverEnding =
        subscription.mainCover.status === 'Canceled' ||
        subscription.mainCover.currentCycleStatus === 'Canceled' ||
        isSubscriptionPaymentFailed;
    const isCoverScheduled = subscription.mainCover.status === 'Scheduled';

    const content = {
        coverScheduled: coverListCardContent.formatString(coverListCardContent.coverScheduled, {
            startDate: unixNumToString(subscription.mainCover.activeFrom, timeZone, DateFormat.VERBOSE_WITH_TIMEZONE),
        }),
        subscriptionRenews: subscription.mainCover.nextCycleAt
            ? coverListCardContent.formatString(coverListCardContent.subscriptionRenews, {
                  // Plus 1 second to get next cycle start time
                  renewDate: unixNumToString(
                      subscription.mainCover.nextCycleAt,
                      timeZone,
                      DateFormat.VERBOSE_WITH_TIMEZONE,
                  ),
              })
            : '',
        subscriptionEnding: coverListCardContent.formatString(coverListCardContent.subscriptionEnding, {
            endDate: unixNumToString(subscription.mainCover.activeTo, timeZone, DateFormat.VERBOSE_WITH_TIMEZONE),
        }),
        subscriptionCancelled: coverListCardContent.formatString(coverListCardContent.subscriptionCancelled, {
            pds: (
                <ExternalLink
                    to={ExternalLinks.productDisclosureStatement}
                    label={common.productDisclosureStatementShort}
                />
            ),
        }),
        subscriptionPaymentFailed: coverListCardContent.formatString(coverListCardContent.subscriptionPaymentFailed, {
            billingCenter: (
                <Button
                    className="cover-list-card__billing-center"
                    variant="link"
                    label={common.billingCenter}
                    onClick={onBillingCenterClick}
                />
            ),
        }),
        cancelCoverAriaLabel: coverListCardContent.formatString(coverListCardContent.cancelCoverAriaLabel, {
            coverName: productInfo.name,
        }),
        innerClickTitle: coverListCardContent.manageCoverLabel,
        onInnerClick: () => {
            window.location.href = Routes.PROFILE_MANAGE_COVER;
        },
    };

    if (isCoverEnding) {
        return {
            leftIcon: productInfo.icon,
            rightIcon: (
                <div className="cover-list-card__active-badge-with-cancel-wrapper">
                    <ActiveBadge />
                </div>
            ),
            header: productInfo.name,
            customDataElement: (
                <>
                    <p className="cover-list-card__action-item-subtext">
                        {isCoverEnding ? content.subscriptionEnding : content.subscriptionRenews}
                    </p>
                    {renderPdsVersion(subscription.PDSVersion)}
                </>
            ),
            innerClickTitle: coverListCardContent.manageCoverLabel,
            onInnerClick: () => {
                window.location.href = Routes.PROFILE_MANAGE_COVER;
            },
            additionalContext: (
                <p className="cover-list-card__action-item-addition-context">
                    {isSubscriptionPaymentFailed ? content.subscriptionPaymentFailed : content.subscriptionCancelled}
                </p>
            ),
        };
    }

    if (isCoverScheduled) {
        return {
            leftIcon: productInfo.icon,
            rightIcon: (
                <button
                    type="button"
                    onClick={() => onSubscriptionCancel(subscription.mainCover.insuranceCoverId)}
                    className="cover-list-card__active-badge-with-cancel-button"
                    aria-label={coverListCardContent
                        .formatString(coverListCardContent.cancelCoverAriaLabel, {
                            coverName: productInfo.name,
                        })
                        .toString()}
                >
                    <img src={cancelIcon} alt="" className="cover-list-card__active-badge-with-cancel-icon" />
                </button>
            ),
            header: productInfo.name,
            customDataElement: (
                <>
                    <p className="cover-list-card__action-item-subtext">{content.coverScheduled}</p>
                    {renderPdsVersion(subscription.PDSVersion)}
                </>
            ),
            innerClickTitle: coverListCardContent.manageCoverLabel,
            onInnerClick: () => {
                window.location.href = Routes.PROFILE_MANAGE_COVER;
            },
            ariaLabel: content.cancelCoverAriaLabel as string,
        };
    }

    return {
        leftIcon: productInfo.icon,
        rightIcon: (
            <div className="cover-list-card__active-badge-with-cancel-wrapper">
                <ActiveBadge />
                <button
                    type="button"
                    onClick={() => onSubscriptionCancel(subscription.mainCover.insuranceCoverId)}
                    className="cover-list-card__active-badge-with-cancel-button"
                    aria-label={coverListCardContent
                        .formatString(coverListCardContent.cancelCoverAriaLabel, {
                            coverName: productInfo.name,
                        })
                        .toString()}
                >
                    <img src={cancelIcon} alt="" className="cover-list-card__active-badge-with-cancel-icon" />
                </button>
            </div>
        ),
        header: productInfo.name,
        customDataElement: (
            <>
                <p className="cover-list-card__action-item-subtext">{content.subscriptionRenews}</p>
                {renderPdsVersion(subscription.PDSVersion)}
            </>
        ),
        ariaLabel: content.cancelCoverAriaLabel as string,
    };
};

export default createSubscriptionCoverListItem;
