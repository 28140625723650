import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import CdnService from '../../services/cdn-service/CdnService';
import Cover from '../../utils/constants/Cover';
import getPdsVersion from '../../utils/getPdsVersion';
import { ProductOption } from '../models/ProductGroupsResponse';
import { Destination } from '../models/RoamingDestinations';
import { useProduct } from './ProductContext';
import { PurchaseState } from '../../types/PurchaseState';

const env = process.env.REACT_APP_NETLIFY_ENV;

// Interfaces
export interface RoamingBaymaxState {
    initialised: boolean;
    loading: boolean;
    error: boolean;
    chosenProductGroup: string;
    selectedProductOption: ProductOption | null;
    destinations: Destination[];
}

interface ActionsState {
    clearCurrentRoamingPurchase: () => void;
    setRoamingDetailsByAttr: (attr: keyof RoamingBaymaxState, value?: any) => void;
}

// Initial Roaming Baymax state
export const initialState: RoamingBaymaxState = {
    initialised: false,
    loading: true,
    error: false,
    selectedProductOption: null,
    destinations: [],
    chosenProductGroup: '',
};

export type IRoamingBaymaxContext = RoamingBaymaxState & ActionsState;

export const RoamingBaymaxContext = createContext<any>(initialState);

export const useRoamingBaymax = (): IRoamingBaymaxContext => {
    const context: IRoamingBaymaxContext = useContext(RoamingBaymaxContext);

    if (typeof context === 'undefined') {
        throw new Error('Roaming Baymax Context must be used within the RoamingBaymaxProvider');
    }
    return context;
};

export const RoamingBaymaxProvider: React.FC = (props) => {
    const [state, setState] = useState(initialState);
    const location = useLocation<PurchaseState>();
    const { initialised: baymaxProductInitialised, products, productGroups } = useProduct();

    useEffect(() => {
        if (env === 'dev' || env === 'test') {
            console.log('Roaming Baymax State - ', state);
        }
    }, [state]);

    const clearCurrentRoamingPurchase = () => {
        setState(initialState);
    };

    const setRoamingDetailsByAttr = (attr: keyof RoamingBaymaxState, value?: any) => {
        setState((prevState) => {
            return {
                ...prevState,
                [attr]: value,
            };
        });
    };

    const {
        isError: fetchingDestinationsError,
        data: fetchedDestinations,
        isSuccess: fetchingDestinationsSucceeded,
    } = useQuery({
        queryKey: ['destinations'],
        queryFn: () => {
            return CdnService.getCoverConfig({
                coverCode: Cover[state.selectedProductOption?.representedByCoverCode as keyof typeof Cover],
                PDSVersion: getPdsVersion(
                    Cover[state.selectedProductOption?.representedByCoverCode as keyof typeof Cover],
                ),
            });
        },
        enabled: !!products && !!productGroups && !!state.selectedProductOption,
        retry: 3,
    });

    useEffect(() => {
        if (fetchingDestinationsSucceeded) {
            setRoamingDetailsByAttr('destinations', fetchedDestinations);
            setRoamingDetailsByAttr('loading', false);
            setRoamingDetailsByAttr('initialised', true);
        }
        if (fetchingDestinationsError) {
            setRoamingDetailsByAttr('error', true);
            setRoamingDetailsByAttr('loading', false);
            setRoamingDetailsByAttr('initialised', true);
        }
    }, [fetchedDestinations, fetchingDestinationsError, fetchingDestinationsSucceeded]);

    useEffect(() => {
        if (baymaxProductInitialised) {
            const chosenProductGroup =
                location.state && location.state.selectedProductGrouping
                    ? location.state.selectedProductGrouping
                    : location.pathname.split('/')[2];
            setRoamingDetailsByAttr('chosenProductGroup', chosenProductGroup);
            // Set first product option as selected product option
            // as roaming only has one option for now
            setRoamingDetailsByAttr(
                'selectedProductOption',
                productGroups.find((x) => x.id.toLowerCase() === 'fliproaming')?.options[0],
            );
        }
    }, [baymaxProductInitialised, location.pathname, location.state, productGroups]);

    const value: IRoamingBaymaxContext = {
        ...state,
        setRoamingDetailsByAttr,
        clearCurrentRoamingPurchase,
    };

    return <RoamingBaymaxContext.Provider value={value} {...props} />;
};
