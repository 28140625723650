import classNames from 'classnames';
import { FC } from 'react';
import { Modifier } from 'react-day-picker';
import { useHistory } from 'react-router';
import Analytics from '../../../analytics/Analytics';
import { ProductOption } from '../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../business-logic/models/ProductResponse';
import Button from '../../../components/button/Button';
import DatePickerModal from '../../../components/date-picker-modal/DatePickerModal';
import Fieldset from '../../../components/form/fieldset/Fieldset';
import Layout from '../../../components/layout/Layout';
import { NewDatePickerModes } from '../../../components/new-date-picker/types';
import PdsTmdDisclaimer from '../../../components/pds-tmd-disclaimer/PdsTmdDisclaimer';
import SlideInCoverTermsCTA from '../../../components/slide-in-cover-terms-cta/SlideInCoverTermsCTA';
import scheduleCoverBaymaxContent from '../../../content/ui/screens/schedule-cover/scheduleCoverBaymax';
import withContent from '../../../hoc/with-content/withContent';
import Cover from '../../../utils/constants/Cover';
import CoverCode from '../../../utils/constants/CoverCode';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import PageTitles from '../../../utils/constants/PageTitles';
import getPdsVersion from '../../../utils/getPdsVersion';
import { Route } from '../../../utils/Routes';
import CoverTile from './components/cover-tile/CoverTile';

import './ScheduleCoverBodyBaymax.scss';

const contentMap = {
    paymentOptionLegend: 'ui.paymentOptionLegend',
    heading: 'ui.heading',
    selectCoverDates: 'ui.selectCoverDates',
    benefitsCTA: 'ui.benefitsCTA',
    continueCTA: 'ui.continueCTA',
};

interface ScheduleCoverBodyCoverType {
    coverCode: string;
    coverType: CoverTypeId;
    label: string;
    currentProduct: ProductOption;
}

interface ScheduleCoverBodyBaymaxProps {
    products: ProductResponse[];
    content: Record<keyof typeof contentMap, string>;
    selectedCover?: ProductOption | null;
    coversAvailable: ProductOption[];
    loading: boolean;
    datePickerMode: NewDatePickerModes;
    daysToSchedule: Date[];
    disabledDays: Modifier[];
    selectCover: (value: ProductOption) => void;
    handleDateSubmission: (dates: Date[]) => void;
    canSelectDates: boolean;
    canCheckout: boolean;
    proceedToCheckout: () => void;
    handleCalendarOpen: () => void;
}

const ScheduleCoverBodyBaymax: FC<ScheduleCoverBodyBaymaxProps> = ({
    products,
    content,
    selectedCover,
    coversAvailable,
    loading,
    datePickerMode,
    daysToSchedule,
    disabledDays,
    selectCover,
    handleDateSubmission,
    canSelectDates,
    canCheckout,
    proceedToCheckout,
    handleCalendarOpen,
}: ScheduleCoverBodyBaymaxProps) => {
    const history = useHistory();

    // For rendering options at larger than xs breakpoint
    const coverOptions = coversAvailable.reduce((acc: ScheduleCoverBodyCoverType[], cur, index) => {
        const coverLabel = {
            coverCode: cur.representedByCoverCode,
            coverType:
                products.find((y) => y.productSpec.mainCoverType.coverCode === cur.representedByCoverCode)?.productSpec
                    .mainCoverTypeId ?? CoverTypeId.SINGLE_V1,
            label: cur.paymentModelTag,
            currentProduct: cur,
        };

        acc.push({
            coverCode: coverLabel.coverCode,
            coverType: coverLabel.coverType as CoverTypeId,
            label:
                acc.findIndex((x) => x.coverType === coverLabel.coverType) > -1 && index !== 0 ? '' : coverLabel.label,
            currentProduct: coverLabel.currentProduct,
        });
        return acc;
    }, []);

    // For rendering options at smaller than xs breakpoint
    const mobileCoverOptions = coverOptions.reduce(
        (acc: { coverType: CoverTypeId; label: string; options: ScheduleCoverBodyCoverType[] }[], current) => {
            const existingGroup = acc.find((item) => item.coverType === current.coverType);
            if (existingGroup) {
                existingGroup.options.push(current);
            } else {
                acc.push({
                    coverType: current.coverType,
                    label: current.label,
                    options: [current],
                });
            }
            return acc;
        },
        [],
    );

    const trackBenefitsDrawer = (coverCode: any) => {
        const pageName = PageTitles[history?.location.pathname as Route];
        Analytics.trackBenefitsDrawerViewed(pageName, coverCode);
    };

    return (
        <Layout title={content.heading} showLoading={loading} showBackButton>
            <Fieldset
                className="schedule-cover-body-baymax__cover-tiles"
                visuallyHideLegend
                legend={
                    <span className="schedule-cover-body-baymax__choose-option-legend">
                        {content.paymentOptionLegend}
                    </span>
                }
            >
                <div className="schedule-cover-body-baymax__cover-tags">
                    {coverOptions.map((x, index) => (
                        <div
                            key={x.coverCode}
                            className={classNames('schedule-cover-body-baymax__cover-tag', {
                                'schedule-cover-body-baymax__cover-tag--single': x.coverType === CoverTypeId.SINGLE_V1,
                                'schedule-cover-body-baymax__cover-tag--subscription':
                                    x.coverType === CoverTypeId.SUBSCRIPTION_V1,
                                'schedule-cover-body-baymax__cover-tag--start':
                                    coverOptions.findIndex((y) => y.coverType === x.coverType) === index,
                                'schedule-cover-body-baymax__cover-tag--end':
                                    coverOptions.findLastIndex((y) => y.coverType === x.coverType) === index,
                            })}
                        >
                            <span>{x.label}</span>
                        </div>
                    ))}
                </div>
                <div className="schedule-cover-body-baymax__cover-options">
                    {coversAvailable.map((product) => (
                        <CoverTile
                            key={product.id}
                            isActive={selectedCover?.representedByCoverCode === product.representedByCoverCode}
                            value={product as ProductOption}
                            onChange={(value) => {
                                selectCover(value);
                            }}
                            name={product.productByLine}
                            price={product.price}
                            title={product.productLabel!}
                        />
                    ))}
                </div>
            </Fieldset>
            <Fieldset
                className="schedule-cover-body-baymax__cover-tiles--mobile"
                visuallyHideLegend
                legend={
                    <span className="schedule-cover-body-baymax__choose-option-legend">
                        {content.paymentOptionLegend}
                    </span>
                }
            >
                {mobileCoverOptions.map((optionGroup) => (
                    <div className="schedule-cover-body-baymax__cover-tags--mobile">
                        <div
                            key={optionGroup.coverType}
                            className={classNames('schedule-cover-body-baymax__cover-tag--mobile', {
                                'schedule-cover-body-baymax__cover-tag--single':
                                    optionGroup.coverType === CoverTypeId.SINGLE_V1,
                                'schedule-cover-body-baymax__cover-tag--subscription':
                                    optionGroup.coverType === CoverTypeId.SUBSCRIPTION_V1,
                            })}
                        >
                            <span>{optionGroup.label}</span>
                        </div>
                        <div className="schedule-cover-body-baymax__cover-options--mobile">
                            {optionGroup.options.map((option) => (
                                <CoverTile
                                    key={option.currentProduct.id}
                                    isActive={
                                        selectedCover?.representedByCoverCode ===
                                        option.currentProduct.representedByCoverCode
                                    }
                                    value={option.currentProduct as ProductOption}
                                    onChange={(value) => {
                                        selectCover(value);
                                    }}
                                    name={option.currentProduct.productByLine}
                                    price={option.currentProduct.price}
                                    title={option.currentProduct.productLabel!}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </Fieldset>
            <p className="schedule-cover-body-baymax__select-cover-dates">{content.selectCoverDates}</p>
            <div className="schedule-cover-body-baymax__date-picker-modal-wrapper">
                <DatePickerModal
                    value={daysToSchedule}
                    onChange={(dates) => handleDateSubmission(dates)}
                    mode={datePickerMode}
                    disabled={!canSelectDates}
                    disabledDays={disabledDays}
                    onCalendarOpen={handleCalendarOpen}
                />
            </div>
            {selectedCover && (
                <div className="schedule-cover-body-baymax__slide-in-benefits-wrapper">
                    <SlideInCoverTermsCTA
                        coverCode={selectedCover.representedByCoverCode as CoverCode}
                        pdsVersion={getPdsVersion(Cover[selectedCover.representedByCoverCode as keyof typeof Cover])}
                        benefitsContent={{
                            benefitsCTA: content.benefitsCTA,
                        }}
                        trackDrawerOpen={() => trackBenefitsDrawer(selectedCover.representedByCoverCode)}
                    />
                </div>
            )}
            <Button
                label={content.continueCTA}
                width="full"
                className="schedule-cover-body-baymax__continue-cta"
                disabled={!canCheckout}
                onClick={proceedToCheckout}
            />
            <PdsTmdDisclaimer />
        </Layout>
    );
};

export default withContent(ScheduleCoverBodyBaymax, contentMap, scheduleCoverBaymaxContent);
