import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Analytics from '../../analytics/Analytics';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import { Tabs } from '../../components/tabs/Tabs';
import purchaseOptionsContent from '../../content/ui/screens/purchase-options/purchaseOptions';
import withContent from '../../hoc/with-content/withContent';
import useProductOptionsAvailableForPurchase from '../../hooks/useProductOptionsAvailableForPurchase';
import { PurchaseState } from '../../types/PurchaseState';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import parseString from '../../utils/parseString';
import Routes from '../../utils/Routes';
import PurchaseOptionsRadioGroup from './components/purchase-options-radio-group/PurchaseOptionsRadioGroup';

import './PurchaseOptions.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
    singleCoverTabLabel: 'ui.singleCoverTabLabel',
    subscriptionCoverTabLabel: 'ui.subscriptionCoverTabLabel',
    cta: 'ui.cta',
};

interface PurchaseOptionsProps {
    content: Record<keyof typeof contentMap, string>;
}

const PurchaseOptions: React.FC<PurchaseOptionsProps> = ({ content }) => {
    const location = useLocation<PurchaseState>();
    const history = useHistory();
    // Casting as string, non valid paths will be removed by 404 at router
    const chosenProductGroupId = location.pathname.split('/').pop() as string;
    const query = new URLSearchParams(location.search);

    const { productGroups, products } = useProduct();
    const productOptionsAvailable = useProductOptionsAvailableForPurchase(chosenProductGroupId);

    const [selectedProductOptionId, setSelectedProductOptionId] = useState<string | null>(null);

    const singleCoverList = useMemo(
        () =>
            productOptionsAvailable.filter((productOption) => {
                const product = products.find(
                    (p) => p.productSpec.mainCoverType.coverCode === productOption.representedByCoverCode,
                );
                return product?.productSpec.mainCoverTypeId === CoverTypeId.SINGLE_V1;
            }),
        [productOptionsAvailable, products],
    );

    const selectedTabOnLoad = () => {
        switch (query.get('purchaseOption')?.toLowerCase()) {
            case 'prepaid':
                return 0;
            case 'subscribe':
                return 1;
            default:
                return null;
        }
    };

    const subscriptionCoverList = useMemo(
        () =>
            productOptionsAvailable.filter((productOption) => {
                const product = products.find(
                    (p) => p.productSpec.mainCoverType.coverCode === productOption.representedByCoverCode,
                );
                return product?.productSpec.mainCoverTypeId === CoverTypeId.SUBSCRIPTION_V1;
            }),
        [productOptionsAvailable, products],
    );

    const [singleIntroTitle, subscriptionIntroTitle] = useMemo(() => {
        const selectedProductGroup = productGroups.find(
            (group) => group.id.toLowerCase() === chosenProductGroupId.toLowerCase(),
        );

        return [
            selectedProductGroup?.introByCoverType['single-v1'],
            selectedProductGroup?.introByCoverType['subscription-v1'],
        ];
    }, [chosenProductGroupId, productGroups]);

    const resetProductOptionSelection = () => setSelectedProductOptionId(null);

    const handleContinue = () => {
        Analytics.trackProductSelected({
            coverCode: productOptionsAvailable.find((x) => x.id === selectedProductOptionId)?.representedByCoverCode,
        });
        history.push({
            pathname: Routes.SELECT_DATES,
            state: {
                selectedProductGrouping: chosenProductGroupId,
                selectedProductOption: productOptionsAvailable.find((x) => x.id === selectedProductOptionId),
                destination: null,
                coverStartDates: [],
            },
        });
    };

    const trackPurchaseTabChange = (tabIndex: number) => {
        Analytics.trackTabChange('Purchase Options', {
            tab: tabIndex === 0 ? 'Prepaid' : 'Subscription',
        });
    };

    // Analytics
    useEffect(() => {
        if (!chosenProductGroupId) return;

        const productGroupLabel =
            productGroups.find((x) => x.id.toLowerCase() === chosenProductGroupId?.toLowerCase())?.name ?? '';

        Analytics.trackProductListViewed(productGroupLabel, productOptionsAvailable.map((x) => x.coverCodes).flat());
    }, [chosenProductGroupId, productGroups, productOptionsAvailable]);

    return (
        <Layout
            title={content.heading}
            description={content.description}
            showBackButton
            className="purchase-options__layout"
        >
            <Tabs
                className="purchase-options__tabs"
                onTabChange={resetProductOptionSelection}
                trackTabChange={(tabIndex: number) => {
                    trackPurchaseTabChange(tabIndex);
                }}
                initialTab={selectedTabOnLoad()}
                tabs={[
                    {
                        id: 'single-cover',
                        label: content.singleCoverTabLabel,
                        content: (
                            <div className="purchase-options__tab-content">
                                {singleIntroTitle && (
                                    <p className="purchase-options__intro">{parseString(singleIntroTitle)}</p>
                                )}
                                <PurchaseOptionsRadioGroup
                                    productOptions={singleCoverList}
                                    legend={content.singleCoverTabLabel}
                                    selectedProductOptionId={selectedProductOptionId}
                                    onChange={setSelectedProductOptionId}
                                />
                            </div>
                        ),
                    },
                    {
                        id: 'subscription-cover',
                        label: content.subscriptionCoverTabLabel,
                        content: (
                            <div className="purchase-options__tab-content">
                                {subscriptionIntroTitle && (
                                    <p className="purchase-options__intro">{parseString(subscriptionIntroTitle)}</p>
                                )}
                                <PurchaseOptionsRadioGroup
                                    productOptions={subscriptionCoverList}
                                    legend={content.subscriptionCoverTabLabel}
                                    selectedProductOptionId={selectedProductOptionId}
                                    onChange={setSelectedProductOptionId}
                                />
                            </div>
                        ),
                    },
                ]}
            />
            <Sticky>
                <Button label={content.cta} onClick={handleContinue} width="full" disabled={!selectedProductOptionId} />
            </Sticky>
        </Layout>
    );
};

export default withContent(PurchaseOptions, contentMap, purchaseOptionsContent);
