import DayPassIcon from '../../../assets/images/cover/day-pass.svg';
import Cover from '../../../utils/constants/Cover';
import { FlipKids90DaysPdsVersion } from '../../../utils/constants/PdsVersion';
import { ProductInfo } from './types';

const flipKids90DaysV1: ProductInfo = {
    id: Cover.FLIP_KIDS_90_DAYS,
    icon: DayPassIcon,
    name: 'Flip Kids 90 Day',
    group: 'Flip Kids',
    pluralisedName: 'Flip Kids 90 Days',
    price: '$99',
    priceTimePeriod: '90 Day',
    description: 'One time payment',
    primaryFeatures: ['Pay by the week', 'Book ahead for upcoming activities, events, or trips'],
    secondaryFeatures: ['Instant activation', 'Lightning-fast claims', 'Get up to $20,000 depending on your injury'],
    paymentModel: '90 Day',
    coverUnitDuration: 'per 90 Days',
    paymentModelDescription: 'One time payment',
    shortDescription: 'Add as many 90 days as you want',
    benefitDescription: 'Claim as many times up to <strong>$20k</strong>',
};

const flipKids90DaysProductInfo: Record<FlipKids90DaysPdsVersion, ProductInfo> = {
    [FlipKids90DaysPdsVersion.FLIP_2_1]: flipKids90DaysV1,
};

export default flipKids90DaysProductInfo;
