import { FC, useState } from 'react';
import CoverCode from '../../utils/constants/CoverCode';
import { MainCoverPdsVersion } from '../../utils/constants/PdsVersion';
import Button from '../button/Button';
import SlideInCoverTerms from '../slide-in-cover-terms/SlideInCoverTerms';

import './SlideInCoverTermsCTA.scss';

interface SlideInCoverTermsCTAProps {
    coverCode: CoverCode;
    pdsVersion: MainCoverPdsVersion;
    benefitsContent: {
        benefitsCTA: string;
    };
    className?: string;
    trackDrawerOpen: () => void;
}

const SlideInCoverTermsCTA: FC<SlideInCoverTermsCTAProps> = ({
    trackDrawerOpen,
    coverCode,
    pdsVersion,
    benefitsContent,
    className,
}) => {
    const [isBenefitsOpen, setIsBenefitsOpen] = useState(false);

    return (
        <div className={className}>
            <Button
                variant="drawer-link"
                className="slide-in-cover-terms-cta__see-more-button"
                label={benefitsContent ? benefitsContent.benefitsCTA : 'View benefits and cover details'}
                onClick={() => {
                    setIsBenefitsOpen(true);
                    trackDrawerOpen();
                }}
                width="full"
            />
            <SlideInCoverTerms
                coverCode={coverCode as CoverCode}
                isOpen={isBenefitsOpen}
                pdsVersion={pdsVersion as MainCoverPdsVersion}
                onOpen={() => setIsBenefitsOpen(true)}
                onClose={() => setIsBenefitsOpen(false)}
            />
        </div>
    );
};

export default SlideInCoverTermsCTA;
