import classNames from 'classnames';
import React, { KeyboardEventHandler, useState } from 'react';
import { TabButton } from '../tab-button/TabButton';
import './Tabs.scss';

interface Tab {
    id: string;
    label: string;
    content: React.ReactNode;
}

export interface TabsProps {
    tabs: Tab[];
    ariaLabel?: string;
    className?: string;
    onTabChange?: () => void;
    trackTabChange?: (tabIndex: number) => void;
    initialTab: number | null;
}

export const Tabs: React.FC<TabsProps> = ({ tabs, ariaLabel, className, onTabChange, trackTabChange, initialTab }) => {
    const [activeTab, setActiveTab] = useState(initialTab || 0);

    const handleTabChange = (newTab: number) => {
        if (onTabChange) {
            onTabChange();
        }
        if (trackTabChange) {
            trackTabChange(newTab);
        }
        setActiveTab(newTab);
    };

    // Listen to left right arrow tabs navigation for accessibility
    const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
        if (e.key === 'ArrowRight') {
            const newActiveTab = activeTab + 1;
            if (newActiveTab === tabs.length) {
                handleTabChange(0);
            } else {
                handleTabChange(newActiveTab);
            }
        }
        if (e.key === 'ArrowLeft') {
            const newActiveTab = activeTab - 1;
            if (newActiveTab < 0) {
                handleTabChange(tabs.length - 1);
            } else {
                handleTabChange(newActiveTab);
            }
        }
    };

    return (
        <>
            <div role="tablist" aria-label={ariaLabel} className={classNames('tabs', className)}>
                {tabs.map((tab, index) => (
                    <TabButton
                        key={tab.id}
                        id={tab.id}
                        label={tab.label}
                        isActive={activeTab === index}
                        onClick={() => {
                            handleTabChange(index);
                        }}
                        onKeyDown={handleKeyDown}
                    />
                ))}
                <div className="tabs__divider" />
            </div>
            {tabs.map((tab, index) => (
                <div
                    key={tab.id}
                    id={`tabpanel-${tab.id}`}
                    role="tabpanel"
                    tabIndex={0}
                    className={classNames(activeTab !== index && 'tabs_panel--hidden')}
                >
                    {tab.content}
                </div>
            ))}
        </>
    );
};
